@import "../../../def";

.table-placeholder {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  color: var(--color-text-4);
  white-space: nowrap;
  text-align: center; // if multiline
  line-height: 175%;

  &.animated {
    animation: table-placeholder-appear 250ms ease;
  }

  .icon-large {
    display: block;
    font-size: 64px;
    color: var(--color-text-4);
    margin-bottom: 24px;
  }

  a {
    color: var(--color-primary);
  }

  &.absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .title,
  .subtitle {
    white-space: nowrap;
    position: relative;
    color: var(--color-text-4);
  }

  .title {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 8px;
  }

  .subtitle {
    font-size: 18px;
  }

  &[data-size="small"] {
    font-size: 14px;

    .icon-large {
      margin-bottom: 12px;
    }
  }
}

@keyframes table-placeholder-appear {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}