@import "../../../def";

.dateList {
  position: relative;
  z-index: 1; //  Workaround for ::before (with z-index -1) not rendering.
}

.weekDayList,
.dateList {
  display: grid;
  grid-template-columns: repeat(7, 42px);
  grid-auto-flow: row;
  grid-auto-rows: 42px;
  background-color: var(--color-input-background);
}

.weekDayListItem {
  color: var(--color-text-2);
}

.weekDayListItem,
.dateItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dateItem {
  position: relative;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  border-radius: 50%;
  font-size: 13px;
  transition: color 150ms ease-out;
  
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    background-color: var(--color-element-hover);
    transition: opacity 150ms ease-out, transform 150ms ease-out, background-color 150ms ease-out;
    pointer-events: none;
    z-index: -1;
  }

  &:not(.selected) {
    &:not(:hover), &.disabled {
      &.otherMonth,
      &.disabled {
        color: var(--color-text-4);
      }
  
      &::before {
        opacity: 0;
        transform: scale(0.5);
      }
    }
  
    &.intermediate {
      &::before {
        opacity: 1;
        transform: scale(0.75);
      }
    }

    &:active {
      &::before {
        background-color: var(--color-element-active);
      }
    }
  }

  &.today {
    border: 2px solid var(--color-primary);
    color: var(--color-primary);
  }

  &.selected {
    &::before {
      background-color: var(--color-primary);
    }

    color: var(--color-background-1);
  }

  &.disabled {
    cursor: not-allowed;
  }
}