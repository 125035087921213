.login-router {
  .page-enter,
  .page-exit,
  .page-enter-active,
  .page-exit-active {
    position: absolute;
    left: 0;
    right: 0;
    transform: none !important;
  }

  .transition-preserve,
  h1 {
    transform: none !important;
  }

  $ease-out: cubic-bezier(0.165, 0.84, 0.44, 1);
  $ease-out-opacity: cubic-bezier(0.23, 1, 0.32, 1); // sligthly quicker
  $dur: 250ms;

  .page-enter {
    opacity: 0.01;

    .page-background-icon {
      opacity: 0.01;
    }

    &.page-enter-active {
      opacity: 1;
      transform: none;
      transition: opacity $dur $ease-out-opacity;

      .page-background-icon {
        opacity: 1;
        transition: opacity $dur $ease-out;
      }
    }
  }

  .page-exit {
    opacity: 1;

    .page-background-icon {
      opacity: 1;
    }
    
    &.page-exit-active {
      opacity: 0.01;
      transition: opacity $dur $ease-out-opacity;

      .page-background-icon {
        opacity: 0.01;
        transition: opacity $dur $ease-out;
      }
    }
  }
}