$duration: 350ms;
$duration-debug-slow: 20s;
$duration-debug-very-slow: 100s;
$easing: cubic-bezier(0.33, 1, 0.68, 1);

.children {
  position: absolute;
  left: 0;
  top: 0;
}

.container {
  position: relative;

  &.transitionWidth {
    transition: width $duration $easing;

    &[data-debug="slow"] {
      transition-duration: $duration-debug-slow;
    }

    &[data-debug="very-slow"] {
      transition-duration: $duration-debug-very-slow;
    }
  }

  &.transitionHeight {
    transition: height $duration $easing;

    &[data-debug="slow"] {
      transition-duration: $duration-debug-slow;
    }

    &[data-debug="very-slow"] {
      transition-duration: $duration-debug-very-slow;
    }
  }

  &.transitionWidth.transitionHeight {
    transition: width $duration $easing, height $duration $easing;

    &[data-debug="slow"] {
      transition-duration: $duration-debug-slow, $duration-debug-slow;
    }

    &[data-debug="very-slow"] {
      transition-duration: $duration-debug-very-slow, $duration-debug-very-slow;
    }
  }

  &.transitioning.overflowHiddenDuringTransition {
    overflow: hidden;
  }

  &.transitioning {
    .children {
      user-select: none;
      pointer-events: none;
    }
  }

  &:not(.transitioning) {
    > .children.next {
      position: relative; //  Fixes bug with initial height when containing images
    }
  }
  
  &[data-type="fade"],
  &[data-type="none"] {
    > .children.previous { // > selector fixes a transition type problem with nested transitioned containers.
      animation: fade-out $duration $easing both;
    }

    &.transitioning {
      > .children.next {
        animation: fade-in $duration $easing both;
      }
    }
  }
  
  &[data-type="none"] {
    //  See https://trello.com/c/lnFTEzG7 for an example of a bug which is why we use steps() rather than conditionally rendering children or similar method.
    $instant: steps(1, start);

    > .children.previous {
      animation-timing-function: $instant;
    }

    &.transitioning {
      > .children.next {
        animation-timing-function: $instant;
      }
    }
  }

  &[data-type="fade-slide"] {
    > .children.previous {
      animation: slide-out $duration $easing both;
    }
    
    &.transitioning {
      > .children.next {
        animation: slide-in $duration $easing both;
      }
    }

    &[data-intensity="high"] {
      > .children.previous {
        animation-name: slide-out-high-intensity;
      }
      
      &.transitioning {
        > .children.next {
          animation-name: slide-in-high-intensity;
        }
      }
    }
  }

  &[data-type="slide-left"] {
    > .children.previous {
      animation: slide-out-left $duration $easing both;
    }

    &.transitioning {
      > .children.next {
        animation: slide-in-left $duration $easing both;
      }
    }

    &[data-intensity="high"] {
      > .children.previous {
        animation-name: slide-out-left-high-intensity;
      }
  
      &.transitioning {
        > .children.next {
          animation-name: slide-in-left-high-intensity;
        }
      }
    }
  }

  &[data-type="slide-right"] {
    > .children.previous {
      animation: slide-out-right $duration $easing both;
    }

    &.transitioning {
      > .children.next {
        animation: slide-in-right $duration $easing both;
      }
    }
    
    &[data-intensity="high"] {
      > .children.previous {
        animation-name: slide-out-right-high-intensity;
      }
  
      &.transitioning {
        > .children.next {
          animation-name: slide-in-right-high-intensity;
        }
      }
    }
  }

  &[data-type="slide-up"] {
    > .children.previous {
      animation: slide-out-up $duration $easing both;
    }

    &.transitioning {
      > .children.next {
        animation: slide-in-up $duration $easing both;
      }
    }
  }

  &[data-type="slide-down"] {
    > .children.previous {
      animation: slide-out-down $duration $easing both;
    }

    &.transitioning {
      > .children.next {
        animation: slide-in-down $duration $easing both;
      }
    }
  }
    
  &[data-debug="slow"] {
    > .children.previous { // > selector fixes a transition type problem with nested transitioned containers.
      animation-duration: $duration-debug-slow !important;
    }

    &.transitioning {
      > .children.next {
        animation-duration: $duration-debug-slow !important;
      }
    }
  }
    
  &[data-debug="very-slow"] {
    > .children.previous { // > selector fixes a transition type problem with nested transitioned containers.
      animation-duration: $duration-debug-very-slow !important;
    }

    &.transitioning {
      > .children.next {
        animation-duration: $duration-debug-very-slow !important;
      }
    }
  }
}

@keyframes fade-in {
  from { opacity: 0 }
  to { opacity: 1 }
}

@keyframes fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}

@mixin slide-in($length) {
  from {
    opacity: 0;
    transform: translateY($length);
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  @include slide-in(12px);
}

@keyframes slide-in-high-intensity {
  @include slide-in(36px);
}

@mixin slide-out($length) {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translateY(-$length);
  }
}

@keyframes slide-out {
  @include slide-out(12px);
}

@keyframes slide-out-high-intensity {
  @include slide-out(36px);
}

@mixin slide-out-left($length) {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(-$length);
  }
}

@keyframes slide-out-left {
  @include slide-out-left(12px);
}

@keyframes slide-out-left-high-intensity {
  @include slide-out-left(36px);
}

@mixin slide-in-left($length) {
  from {
    opacity: 0;
    transform: translateX($length);
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in-left {
  @include slide-in-left(12px);
}

@keyframes slide-in-left-high-intensity {
  @include slide-in-left(36px);
}

@mixin slide-out-right($length) {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX($length);
  }
}

@keyframes slide-out-right {
  @include slide-out-right(12px);
}

@keyframes slide-out-right-high-intensity {
  @include slide-out-right(36px);
}

@mixin slide-in-right($length) {
  from {
    opacity: 0;
    transform: translateX(-$length);
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in-right {
  @include slide-in-right(12px);
}

@keyframes slide-in-right-high-intensity {
  @include slide-in-right(36px);
}

@keyframes slide-out-up {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-12px);
  }
}

@keyframes slide-in-up {
  from {
    opacity: 0;
    transform: translateY(12px);
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-out-down {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(12px);
  }
}

@keyframes slide-in-down {
  from {
    opacity: 0;
    transform: translateY(-12px);
  }
  to {
    opacity: 1;
  }
}