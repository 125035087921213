@import "../../../def";

$item-height: 46px;

.dropdown {
  position: absolute;
  top: 100%;
  padding: 8px 0;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  min-width: 120px;
  z-index: 100; //  Keep above the hider.
  max-height: 215px;
  overflow-y: auto;
  user-select: none;
  
  &.maxWidth {
    max-width: 280px;
  }

  .title {
    font-size: 13px;
    padding: 4px 12px;
  }

  &,
  .queryContainer {
    background: var(--color-background-2);
  }

  .queryContainer {
    position: sticky;
    top: -8px;
    z-index: 10;
    max-width: 100%;
    
    .query {
      $ease: cubic-bezier(0.33, 1, 0.68, 1);
      $dur: 250ms;

      padding: 8px 16px;
      padding-top: 12px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid var(--color-background-3);
      
      .icon {
        color: var(--color-text-3);
        transition: opacity $dur $ease;
      }

      &:hover {
        background: var(--color-element-hover);
      }

      &:not(.focused) {
        .icon {
          opacity: 0.5;
        }
      }

      input {
        width: 100%;
        outline: none;
        border: none;
        background: transparent;
        font-size: 15px;
      }  
    }

    + .title {
      margin-top: 6px;
    }
  }
  
  &[data-horizontal-position="right"] {
    transform-origin: top right;
  }

  $duration: 250ms;
  $ease-in: cubic-bezier(0.165, 0.84, 0.44, 1);
  $ease-out: $ease-in;

  opacity: 1;

  &:not(.open) {
    pointer-events: none;

    animation-timing-function: $ease-out, $ease-out;
    animation-name: disappear-bottom;
    animation-fill-mode: both;
    
    &[data-vertical-position="top"] {
      animation-name: disappear-top;
    }
  }

  animation: appear-bottom $duration $ease-in forwards;
  &[data-vertical-position="top"] {
    animation-name: appear-top;
  }

  &.transition {
    transition: top $duration $ease-in, $duration $ease-in;
  }
}

.item {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  display: flex;
  padding: 12px 16px;
  white-space: nowrap;
  text-overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  user-select: none;
  font-weight: normal;
  min-height: $item-height;
  max-height: $item-height;

  [class*="help-icon"] {
    margin: 0 !important;
  }
  
  .taskIndicator {
    width: 14px;
    height: 14px;
  }

  .iconContainer {
    $width: 20px;
    min-width: $width;
    max-width: $width; 
    max-height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:empty {
      display: none;
    }
  }

  .icon {
    margin: 0;
    text-align: left;
    font-size: 16px;

    svg {
      width: 100%;
      height: 100%;
    }

    svg,
    img {
      vertical-align: middle;
    }
  }

  .taskIndicator {
    transform: scale(0.875);
  }

  &,
  a,
  label,
  .icon {
    font-size: 16px;
  }

  &.normal {
    &,
    a,
    label {
      color: var(--color-text-2);
      fill: var(--color-text-2);
    }
  }

  &.danger {
    color: var(--color-danger);
  }

  &.dimmed {
    color: var(--color-text-3);
  }

  &.link {
    color: var(--color-primary);

    &,
    a,
    label,
    .icon {
      font-size: 14.5px;
    }
  }

  &.normal,
  &.link {
    .icon {
      &,
      svg {
        fill: var(--color-accent);
        color: var(--color-accent);
      }
    }
  }

  &:not(.disabled):hover {
    background-color: var(--color-background-3);
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  height: $item-height;
  color: var(--color-text-4);
  box-sizing: border-box;
  padding: 0 4px;
  text-align: center;
}

@keyframes appear-bottom {
  from {
    transform: translateY(-24px);
    opacity: 0;
  }
}

@keyframes disappear-bottom {
  to {
    transform: translateY(-24px);
    opacity: 0;
  }
}

@keyframes appear-top {
  from {
    transform: translateY(24px);
    opacity: 0;
  }
}

@keyframes disappear-top {
  to {
    transform: translateY(24px);
    opacity: 0;
  }
}

//  Declared in index.html – circumvent hash
[id="dropdowns"] {
  .dropdown {
    position: absolute;
  }
}