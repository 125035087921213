@import "../../def";

.viewContainer,
.view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 24px;
  min-height: 100%;
}

.view {
  box-sizing: border-box;
  padding: 24px;
}

.pinList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-grow: 1;

  &.incorrect {
    animation: shake 500ms linear;

    .item {
      border-color: var(--color-danger);
    }
  }

  .item {
    width: 48px;
    height: 48px;
    border: 1px solid var(--color-background-3);
    border-radius: 3px;
    font-size: 20px;
    font-weight: 300;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
}

.numberPad {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100vw;
  max-width: 360px;
  border: 1px solid var(--color-background-3);
  border-radius: 3px;

  &.disabled {
    .item {
      color: var(--color-text-3);
    }
  }

  .item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    user-select: none;
    cursor: pointer;
    font-size: 24px;

    &:hover {
      background-color: var(--color-element-hover);
    }

    &:active {
      background-color: var(--color-element-active);
    }
  }
}

.stretchedField {
  width: 280px;
}

@media (max-width: $breakpoint-sm) {
  .stretchedField {
    width: 100%;
  }
}

@keyframes shake {
  0%,
  100% {
    transform: none;
  }

  20% {
    transform: translateX(-20px);  
  }

  40% {
    transform: translateX(15px);  
  }
  
  60% {
    transform: translateX(-10px);  
  }
  
  80% {
    transform: translateX(5px);  
  }
}

@media (max-width: $breakpoint-sm) {
  .numberPad {
    max-width: unset;
  }
}