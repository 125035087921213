@import "../../../def";

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: $field-width;

  .label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    min-height: 21px; //  Keep a consistent height, even with "immediate" elements. 
    max-height: 21px;
  }

  .label-content {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: 1px solid var(--color-text-3);
      border-radius: 4px;
      transition: opacity 75ms ease-in, transform 75ms ease-in;
      transform: scale(0.95);
      opacity: 0;
    }
    
    &.focus-label {
      &::before {
        opacity: 1;
        transform: scale(1);
        transition-timing-function: ease-out, ease-out;
      }
    }
  }

  &.full-w {
    width: 100%;
  }

  &.label-align-row {
    flex-direction: row;
    align-items: center;

    .label-container {
      width: auto;
      margin-right: 8px;
    }
  }

  .clear {
    position: absolute;
    right: 4px;
    bottom: 8px;
  }

  > .sub {
    font-size: 13px;
    margin: 0 6px;
    color: var(--color-text-3);
    
    .icon {
      display: inline-block;
      margin-right: 4px;
    }
  }
}

.static-value {
  margin-top: 3px;
}

.autogrow-measure {
  position: fixed;
  pointer-events: none;
  visibility: hidden;
}

.autogrow-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .text-input-container {
    flex-shrink: 1;
    width: auto;
    display: inline-flex;
  }
}