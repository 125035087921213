@import "../../../def";

.container {
  position: fixed;
  right: 0;
  bottom: 12px;
  transform: rotate(90deg);
  transform-origin: top right;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.indicator {
  position: relative;
  padding: 4px 12px;
  font-size: 13px;
  border-radius: 0 0 4px 4px;
  color: #fff; // Fixed background color, unaffected by theme.
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  &.support {
    background: #E53935;
    cursor: pointer; // Click to disable.
    pointer-events: auto;
  }

  &.dev {
    background: #0277BD;
  }
  
  &.alpha, &.next {
    background: #9C27B0;
  }
}